<script lang="ts">
    import { Divider, TextButton } from '@allibee/components';
    import { ModalPrivacyPolicy } from '$lib/service';
    import { custom } from '$lib/utils';
    import { _ } from 'svelte-i18n';
    import { twMerge } from 'tailwind-merge';
    const handleOpenPrivacyPolicy = () => {
        custom(ModalPrivacyPolicy, {}, { size: 'lg', maxHeight: '100%' });
    };
</script>

<footer class={twMerge('flex w-full items-center justify-center', $$props.class)}>
    <div class="font-regular flex items-center space-x-2">
        <TextButton on:click={handleOpenPrivacyPolicy} class="text-sm font-bold text-gray-500">{$_('apps.contract.lib.service.helpDesk.FooterHelpDesk.privacyPolicy')}</TextButton>
        <Divider direction="vertical" class="h-4 bg-gray-300" />
        <TextButton href="https://guide.allibee.ai/" target="_blank" class="text-sm text-gray-500">{$_('apps.contract.lib.service.helpDesk.FooterHelpDesk.userGuide')}</TextButton>
        <Divider direction="vertical" class="h-4 bg-gray-300" />
        <TextButton href="https://www.allibee.ai/contact" target="_blank" class="text-sm text-gray-500">{$_('apps.contract.lib.service.helpDesk.FooterHelpDesk.customerCenter')}</TextButton>
    </div>
</footer>
